<template>
    <a
        v-if="link && link.attributes && link.attributes.href"
        v-bind="link.attributes">
        <template v-if="!hasSlotContent">
            {{ link.text }}
        </template>
        <slot />
    </a>
</template>

<script lang="ts">
import {Link} from "@/interfaces/Link";
import {defineComponent, PropType} from "vue";

export default defineComponent({
    name: "SLink",
    props: {
        link: {
            required: true,
            type: Object as PropType<Link>
        }
    },
    computed: {
        hasSlotContent(): boolean {
            return !!this.$slots.default
        }
    }
})
</script>

<style scoped>

</style>
