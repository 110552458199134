
import {defineComponent} from "vue";
import Player from '@vimeo/player';

export default defineComponent({
    name: "VimeoPlayer",
    props: {
        videoId: {
            type: [Number],
            required: true
        }
    },
    emits: [
        'played', 'ready', 'paused', 'ended'
    ],
    data: () => ({
        id: 'some-random-' + Math.random(),
    }),
    mounted() {
        var options = {
            id: this.videoId,
            width: 480
        };

        var player = new Player(this.id, options);
        player.setVolume(0);
        player.play();
        player.ready().then(() => {
            this.$emit('ready', player);
        });
    }
});
