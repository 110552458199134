
import {Ref} from "@vue/reactivity";
import {defineComponent, onMounted, onUnmounted, ref} from "vue";
import YouTubePlayer from "youtube-player";

export default defineComponent({
    name: "YouTube",
    inheritAttrs: false,
    props: {
        width: {
            type: Number,
            default: 480
        },
        height: {
            type: Number,
            default: 320
        },
        autoplay: {
            type: Number,
            default: 1,
            validator: (v) => Number(v) === 0 || Number(v) === 1
        },
        videoId: {
            type: String,
            required: true
        },
        loop: {
            type: Number,
            default: 1,
            validator: (v) => Number(v) === 0 || Number(v) === 1
        }
    },
    emits: [
        'played', 'ready', 'paused', 'ended'
    ],
    setup(props: any, {emit}) {
        let player: any = null;

        const youtubePlayerContainer: Ref<HTMLElement | null> = ref(null);

        const mounted = onMounted(() => {
            let playerVars = {
                autoplay: props.autoplay,
                loop: props.loop,
            }

            if(youtubePlayerContainer.value){
                player = YouTubePlayer(youtubePlayerContainer.value, {
                    width: props.width,
                    height: props.height,
                    videoId: props.videoId,
                    playerVars: playerVars,
                })
                player.on('ready', () => {
                    if(player){
                        player.playVideo();
                    }
                    emit('ready', player);
                })

                player.on('stateChange', (e: any) => {
                    if (e.data === (window as any).YT.PlayerState.ENDED) {
                        emit('ended')
                    } else if (e.data === (window as any).YT.PlayerState.PAUSED) {
                        emit('paused')
                    } else if (e.data === (window as any).YT.PlayerState.PLAYING) {
                        emit('played')
                    }
                });
            }

        });

        const unMounted = onUnmounted(() => {

            if(player){
                player.destroy()
                player = null;
            }
        })

        return {
            mounted,
            unMounted,
            player,
            youtubePlayerContainer
        };
    },
    data() {
        return {
            ready: 0,
        }
    },
    watch: {
        videoId() {
            //this.player.loadVideoById(this.videoId);
            if(this.player){
                this.player.playVideo();
            }
        },
        // list() {
        //     this.player.getPlaylist(this.list)
        //     this.player.playVideo();
        // }
    }
})
