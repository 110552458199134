import Fair from '@/models/Fair';
import FairProfile from '@/models/FairProfile';
import {ComputedRef} from '@vue/reactivity';
import {computed, ref} from 'vue';
import {useRoute, useRouter} from 'vue-router';
import {useStore} from 'vuex';
import {trackFairEvent as externalTrackFairEvent, TrackFairEventType, TrackFairProfileEventType} from '@/api/socket/Client';

let feedbackFormOpened: { [key in EFeedBackSource]?: boolean } = {};

const showFeedbackForm = ref<boolean>(false);

export enum EFeedBackSource {
    timeout = 'timeout',
    mouseout = 'mouseout',
    click = 'click'
}

/**
 * open the feedback form
 *
 * @param force
 * @param source
 */
const openFeedbackForm = (force: boolean = false, source: EFeedBackSource = EFeedBackSource.click) => {
    // if(process.env.NODE_ENV === 'development'){
    //     console.log('trying to open feedback form in dev mode -> please enable this first, otherwise other might get annoyed of this overlay');
    //     return false;
    // }

    let doIt = true;
    switch (source) {
        case EFeedBackSource.click:
            // click will always work ¯\_(ツ)_/¯
            break;
        case EFeedBackSource.timeout:
            if (Object.keys(feedbackFormOpened).length !== 0) {
                doIt = false;
            }
            break;
        case EFeedBackSource.mouseout:
            if (feedbackFormOpened.mouseout) {
                doIt = false;
            }
            break;
    }

    if (!doIt && !force) {
        return false;
    }

    feedbackFormOpened[source] = true;
    showFeedbackForm.value = true;
};

export default function () {

    const router = useRouter();
    const route = useRoute();
    const store = useStore();

    const fairSlug: ComputedRef<string | null> = computed(() => {
        const slug = (route.params?.slug as string) ?? null;
        return slug ?? null;
    });

    const currentFair = computed<Fair|null>(() => {
        return store.getters['fairs/current'];
    })

    // const currentFair: ComputedRef<Fair|null> = computed(() => {
    //     return ;
    // });

    const toggleProfile = (profile: FairProfile | null = null, tab: string | null = null) => {
        if (profile !== null && profile.isPlaceHolder === true) {
            return;
        }

        if (fairSlug.value && fairSlug.value !== null) {
            if (profile && profile.slug) {
                if (route.name !== 'fairProfile' || route.params.profile !== profile.slug) {
                    let params: any = {
                        slug: fairSlug.value,
                        profile: profile.slug,
                    };

                    if (tab !== null) {
                        params.tab = tab;
                    } else if (typeof route.params.tab === 'string') {
                        params.tab = route.params.tab;
                    }

                    if(currentFair.value && currentFair.value.id){
                        externalTrackFairEvent(currentFair.value.id, profile.id, 'tab');
                    }

                    router.push({name: 'fairProfile', params});
                } else {
                    router.push({name: 'fair', params: {slug: fairSlug.value}});
                }
            } else {
                router.push({name: 'fair', params: {slug: fairSlug.value}});
            }
        }
    };

    const profileSlug: ComputedRef<string | null> = computed(() => {
        return (route.params.profile as string) ?? null;
    });

    const currentProfile: ComputedRef<FairProfile | null> = computed(() => {
        return profileSlug.value ? store.getters['fair-profiles/oneBySlug'](profileSlug.value) : null;
    });

    const trackFairEvent = (event: TrackFairEventType, suffix?: string) => {
        if (currentFair.value && currentFair.value.id) {
            externalTrackFairEvent(currentFair.value.id, null, event, suffix);
        } else {
            console.error('currentFair', currentFair);
            console.error('storeValue', store.getters['fairs/current']);
            throw new Error('there is no current fair to track');
        }
    };


    const trackFairProfileEvent = (event: TrackFairProfileEventType, suffix?: string) => {
        if (currentFair.value && currentFair.value.id && currentProfile.value && currentProfile.value.id) {
            externalTrackFairEvent(currentFair.value.id, currentProfile.value.id, event, suffix);
        } else {
            console.error(currentFair);
            throw new Error('there is no current fair to track');
        }
    };


    return {
        toggleProfile,
        fairSlug,
        profileSlug,
        currentProfile,
        currentFair,
        openFeedbackForm,
        showFeedbackForm,
        trackFairEvent,
        trackFairProfileEvent
    };
}
