
import {Link} from "@/interfaces/Link";
import {defineComponent, PropType} from "vue";

export default defineComponent({
    name: "SLink",
    props: {
        link: {
            required: true,
            type: Object as PropType<Link>
        }
    },
    computed: {
        hasSlotContent(): boolean {
            return !!this.$slots.default
        }
    }
})
