<template>
    <div
        v-if="showImage === true"
        class="img video">
        <img
            :src="previewImage"
            alt=""
            @click="initVideo">
    </div>

    <template v-if="enabled === true">
        <Youtube
            v-if="type === 'youtube'"
            v-show="showImage === false"
            :video-id="id"
            :width="480"
            :heigth="320"
            @ready="onReady" />

        <VimeoPlayer
            v-if="type === 'vimeo'"
            v-show="showImage === false"
            ref="player"
            :video-id="id"
            :player-height="480"
            @ready="onReady" />
    </template>
</template>

<script lang="ts">
import VimeoPlayer from "@/components/base/VimeoPlayer.vue";
import {defineComponent} from "vue";
import Youtube from '@/components/base/YouTube.vue'

export default defineComponent({
    name: "SVideo",
    components: {
        Youtube,
        VimeoPlayer
    },
    props: {
        videoData: {
            type: String,
            required: true,
            validator(value) {
                // The value must match one of these strings
                return value !== null && typeof value === 'string' && value.split('|').length === 2;
            }
        }
    },
    data: () => ({
        enabled: false,
        showImage: true
    }),
    computed: {
        previewImage(): string {
            if (this.type === 'youtube') {
                return 'https://img.youtube.com/vi/' + this.id + '/maxresdefault.jpg';
            }

            return '/gfx/white.jpg';
        },
        type(): string {
            return this.videoData ? this.videoData.split('|')[0] : '';
        },
        id(): string|number {
            if(this.type === 'vimeo'){
                return Number.parseInt(this.videoData.split('|')[1])
            }
            return this.videoData ? this.videoData.split('|')[1] : '';
        }
    },
    methods: {
        onReady() {
            this.showImage = false;
        },
        initVideo() {
            this.enabled = true;
        }
    }
})
</script>

<style scoped>

</style>